/*global require, requirejs, define */
requirejs.config({
    baseURL: 'js/dev',
    paths: {
        jQuery: 'libs/jquery',
        fancybox: 'plugins/lightbox/jquery.fancybox',
        jqueryValidation: 'plugins/jquery-validation/jquery.validate',
        jqueryValidationMessagesDe:
            'plugins/jquery-validation/localization/local_msg_de',
        tooltip: 'plugins/bootstrap/tooltip',
        progress: 'plugins/circular-progress-bar/circular-progress-bar',
        app: 'app/app',
        helpers: 'app/helpers',
        contact: 'app/contact',
        animate: 'app/animate'
    },
    shim: {
        app: {
            deps: ['jQuery', 'fancybox']
        }
    },
    config: {},
    catchError: true
});

requirejs.onError = function (err) {
    'use strict';
    console.error('Error:', err);
};

require(['app', 'animate', 'contact', 'progress'], function () {
    'use strict';
    var skillCards = document.querySelectorAll('.skill-card');

    if (skillCards) {
        skillCards.forEach((a) => {
            a.addEventListener('mouseenter', addTurnAnimation);
            a.addEventListener('mouseleave', removeAllTurnAnimation);
        });
    }

    function addTurnAnimation(e) {
        var $trigger = e.currentTarget;
        $trigger.classList.toggle('active');
    }

    function removeAllTurnAnimation() {
        skillCards.forEach((a) => a.classList.remove('active'));
    }
});

define("main", function(){});


/*global define, Modernizr */
define('animate',['helpers'], function (helpers) {
    'use strict';

    function Animate() {
        this.revealer = document.querySelectorAll('.js-animate');
        this.toggleCSSClass();

        if (
            window.requestAnimationFrame &&
            document.documentElement.classList
        ) {
            document.documentElement.classList.add('enhanced');

            window.addEventListener(
                'scroll',
                helpers.throttle(this.toggleCSSClass.bind(this).bind(this), 300)
            );
        }
    }

    Animate.prototype.getViewportSize = function () {
        return {
            width: window.document.documentElement.clientWidth,
            height: window.document.documentElement.clientHeight
        };
    };

    Animate.prototype.getCurrentScroll = function () {
        return {
            x: window.pageXOffset,
            y: window.pageYOffset
        };
    };

    /**
     * Leveraging offset, this function returns the element’s dimensions, and the distance through all its parents to the very top and left of the document.
     */
    Animate.prototype.getElementInfo = function (elem) {
        var offsetTop = 0;
        var offsetLeft = 0;
        var offsetHeight = elem.offsetHeight;
        var offsetWidth = elem.offsetWidth;

        do {
            if (!isNaN(elem.offsetTop)) {
                offsetTop += elem.offsetTop;
            }
            if (!isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft;
            }
        } while ((elem = elem.offsetParent) !== null);

        return {
            top: offsetTop,
            left: offsetLeft,
            height: offsetHeight,
            width: offsetWidth
        };
    };

    /**
     * check if Element is within viewport
     */
    Animate.prototype.isVisible = function (elem) {
        var viewportSize = this.getViewportSize();
        var currentScroll = this.getCurrentScroll();
        var elemInfo = this.getElementInfo(elem);
        var spaceOffset = 0.2;
        var elemHeight = elemInfo.height;
        var elemWidth = elemInfo.width;
        var elemTop = elemInfo.top;
        var elemLeft = elemInfo.left;
        var elemBottom = elemTop + elemHeight;
        var elemRight = elemLeft + elemWidth;

        var checkBoundaries = function () {
            // Defining the element boundaries and extra space offset
            var top = elemTop + elemHeight * spaceOffset;
            var left = elemLeft + elemWidth * spaceOffset;
            var bottom = elemBottom - elemHeight * spaceOffset;
            var right = elemRight - elemWidth * spaceOffset;

            // Defining the window boundaries and window offset
            var wTop = currentScroll.y + 0;
            var wLeft = currentScroll.x + 0;
            var wBottom = currentScroll.y - 0 + viewportSize.height;
            var wRight = currentScroll.x - 0 + viewportSize.width;

            // Check if the element is within boundary
            return (
                top < wBottom && bottom > wTop && left > wLeft && right < wRight
            );
        };

        return checkBoundaries();
    };

    Animate.prototype.toggleCSSClass = function () {
        for (var i = 0, len = this.revealer.length; i < len; i++) {
            var elem = this.revealer[i],
                cssClass = elem.dataset.animClass,
                rating = elem.dataset.rating;

            if (this.isVisible(elem)) {
                elem.innerHTML = this.getPercentage(rating) + '%';
                elem.classList.add(cssClass);
            } else {
                // elem.classList.remove(cssClass);
            }
        }
    };

    Animate.prototype.getPercentage = function (rating) {
        return (100 / 5) * rating;
    };

    return new Animate();
});


/**
 * SOME DESCRIPTION
 *
 * @author: Jessica Meinhold
 * @date: 11/5/2017
 */

/*global define, Modernizr */
define('helpers',["jquery"], function ($) {

    'use strict';

    function JNHelpers() {

    }

    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * N milliseconds. If `immediate` is passed, trigger the function on the
     * leading edge, instead of the trailing.
     * @param func
     * @param wait
     * @param immediate
     * @returns {Function}
     */
    JNHelpers.prototype.debounce = function (func, wait, immediate) {
        var timeout;

        return function () {
            var context = this,
                callNow,
                args = arguments,
                later;

            later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };

            callNow = immediate && !timeout;

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if (callNow) func.apply(context, args);
        };
    };


    JNHelpers.prototype.throttle = function(func, delay) {
        var timer = 0;

        return function() {
            var context = this,
                args = [].slice.call(arguments);

            clearTimeout(timer);
            timer = setTimeout(function() {
                func.apply(context, args);
            }, delay);
        };
    };

    return new JNHelpers();
});

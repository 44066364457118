/*global define, Modernizr */
define('app',['jquery', 'helpers'], function ($, helpers) {
    'use strict';

    function JNIERTH() {
        var body = document.querySelector('body'),
            navpos = document.querySelector('.main-navigation').offsetTop;

        var init = function init() {
            // Remove masthead hastag if on desktop and initial load.
            setMasthead();

            addStickyNav();

            if (document.querySelectorAll('.single').length) {
                $('.fancybox').fancybox({
                    nextEffect: 'fade',
                    prevEffect: 'fade',
                    helpers: {
                        title: {
                            type: 'inside'
                        }
                    }
                });
            }

            window.addEventListener(
                'resize',
                helpers.debounce(setMasthead, 250)
            );
        };

        var addStickyNav = function () {
            window.addEventListener('scroll', function () {
                if ($(this).scrollTop() >= navpos) {
                    body.classList.add('sticky-nav');
                } else {
                    body.classList.remove('sticky-nav');
                }
            });
        };

        /**
         * Removes the masthead hashtag in case we are in the desktop view.
         */
        var setMasthead = function () {
            if (window.location.hash == '#masthead') {
                if (window.innerWidth() <= 1024) {
                    window.location.hash = '';
                }
            }
        };

        init();
    }

    return new JNIERTH();
});


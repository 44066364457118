/*global define, Modernizr */
define('app',["jquery", "helpers"], function ($, helpers) {
    'use strict';

   
    function JNIERTH() {

        var $window = $(window),
            $body = $('body'),
            navpos = $('.main-navigation').offset().top;

        var init = function init() {

            // Remove masthead hastag if on desktop and initial load.
            setMasthead();

            addStickyNav();

            if ($(".single").length) {

                $(".fancybox").fancybox({

                    nextEffect: 'fade',
                    prevEffect: 'fade',
                    helpers: {
                        title: {
                            type: 'inside'
                        }
                    }
                });
            }

            $window.on('resize', helpers.debounce(setMasthead, 250));

        };

        var addStickyNav = function () {

            $window.scroll(function () {
                if ($(this).scrollTop() >= navpos) {
                    $body.addClass("sticky-nav");
                } else {
                    $body.removeClass("sticky-nav");
                }
            });
        };

        /**
         * Removes the masthead hashtag in case we are in the desktop view.
         */
        var setMasthead = function () {
            if (window.location.hash == '#masthead') {

                if ($window.innerWidth() <= 1024) {
                    window.location.hash = '';
                }
            }
        };

        init();
    }

    return new JNIERTH();
});

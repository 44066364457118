/*global define, Modernizr */
define('contact',["jquery","jqueryValidation","jqueryValidationMessagesDe"], function ($,jqVal,jqueryValidationMessagesDe) {

    'use strict';


    var localMsg = '';

    function Contact() {

        this.$contactForm = $('#contactForm');

        if (this.$contactForm.length) {
            this.validateContactForm();
        }
    }

    /**
     * Get the localized file and search for the string
     */
    Contact.prototype.loadJQueryValidationTranslations = function (lang) {
        switch (lang) {
            case 'de':
                localMsg = jqueryValidationMessagesDe;
                break;
            default:
                localMsg = '';
        }
    };


    /**
     * Get the current language in dependance of the current url.
     *
     * @returns {string} de for german, else returns en for english.
     */
    Contact.prototype.getCurrentLanguage = function () {

        if (window.location.pathname.search('/en/') != -1) {
            return 'en';
        }

        return 'de';
    };

    /**
     * Checks the current language in dependance of the current URL
     * @returns stringToTranslate {string} The string to translate
     */
    Contact.prototype.translate = function (stringToTranslate) {

        return (localMsg[stringToTranslate]) ? localMsg[stringToTranslate] : stringToTranslate;

    };

    /**
     * Validates the form on the contact page
     */
    Contact.prototype.validateContactForm = function () {

        // Set the local msg variable
        this.loadJQueryValidationTranslations(this.getCurrentLanguage());

        console.log(this.loadJQueryValidationTranslations(this.getCurrentLanguage()));
        this.$contactForm.validate({
            rules: {
                senderName: {
                    required: true,
                    isOnlyCharacters: false,
                    minlength: 1
                },
                senderEmail: {
                    required: true,
                    email: true
                },
                senderMessage: {
                    required: true,
                    minlength: 5
                },
                recaptcha_response_field: {
                    required: true
                }
            },
            errorPlacement: function (error, element) {

                if(!element.parent().hasClass('error')) {
                    element.parent().addClass('error');
                    error.appendTo(element.parent());
                }
            },
            success: function(label) {
                label.parent().removeClass("error");
            },
            messages: {
                senderName: {
                    required: this.translate('Please enter your name'),
                    isOnlyCharacters: this.translate('Numbers are not allowed'),
                    minlength: this.translate('Your name is too short')
                },
                senderEmail: {
                    required: this.translate('Please enter your email address'),
                    email: this.translate('Please enter a valid email address')
                },
                senderMessage: this.translate('Please enter a message'),
                recaptcha_response_field: {
                    required: this.translate('Please enter the copy from above here')
                }
            }
        });


        $.validator.addMethod("isOnlyCharacters", function (value, element, params) {

            var regex = /^[a-zA-Z]+([ ]*[a-zA-Z]*)$/;

            return regex.test(value);
        });
    };


    return new Contact();
});
